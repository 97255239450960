.root {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  background-color: var(--brown);
  padding: 1rem;
  width: 50%;
  & .modal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .toggle {
      font-family: "Cool Daddy";
      color: var(--beige);
      font-size: 2.5rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
  & .modal_body {
    color: var(--beige);
    font-family: "Cool Daddy";
    font-size: 1.5rem;
    margin-block: 2rem;
  }
}

.modal_footer {
  display: flex;
  align-items: start;
  width: 100%;
  position: relative;

  & > ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    & .nav_item {
      white-space: nowrap;
      padding-inline: 0.5rem;
      margin-right: 0.5rem;
      font-family: "Cool Daddy";
      background-color: var(--beige);
      font-size: 1.375rem;
      border-radius: var(--width-400);
      & > a,
      > div {
        cursor: pointer;
        text-decoration: none;
        color: var(--brown);
        font-size: 1.5rem;
      }
      & img {
        max-width: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .modal_body {
      margin-block: 1.5rem;
    }

    & .modal_footer {
      bottom: 10vh;
    }
  }
}
