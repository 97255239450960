.toggle {
  display: none;
}

.nav_container_mobile {
  padding: 1rem;
  & .toggle {
    font-size: 2rem;
    font-family: "Cool Daddy";
    color: var(--beige);
  }
  display: none;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background: var(--brown);
  z-index: 100;

  & > ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    & .nav_item {
      white-space: nowrap;
      padding: 0.25rem 0.5rem;
      margin-inline: 0.25rem;
      font-family: "Cool Daddy";
      background-color: var(--beige);
      font-size: 1.375rem;
      border-radius: var(--width-400);
      width: fit-content;
      margin-block: 0.3rem;
      & > a,
      > div {
        cursor: pointer;
        text-decoration: none;
        color: var(--brown);
        display: inline-block;
        width: 100%;
        font-size: 1.5rem;
      }
      & img {
        padding: 0;
        max-width: 1.325rem;
      }
    }
  }
}

.nav_header {
  font-size: 0.275rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.375rem;
  align-items: center;

  & .logo {
    width: 137px;
    cursor: pointer;
  }
}

.nav_footer {
  display: flex;
  align-items: start;
  width: 100%;
  bottom: 10vh;
  position: relative;

  & > ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    & .nav_item {
      white-space: nowrap;
      padding-inline: 0.5rem;
      margin-inline: 0.25rem;
      font-family: "Cool Daddy";
      background-color: var(--beige);
      font-size: 1.375rem;
      border-radius: var(--width-400);
      & > a,
      > div {
        cursor: pointer;
        text-decoration: none;
        color: var(--brown);
        font-size: 1.5rem;
      }
      & img {
        max-width: 20px;
      }
    }
  }
}

.nav_container_desktop {
  display: block;
  & > ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    align-items: flex-start;
    & .nav_item {
      white-space: nowrap;
      padding: 0.25rem 0.5rem;
      margin-inline: 0.25rem;
      font-family: "Cool Daddy";
      background-color: var(--brown);
      font-size: 1.375rem;
      border-radius: var(--width-400);
      & > a,
      > div {
        cursor: pointer;
        text-decoration: none;
        color: var(--beige);
        font-size: 1.5rem;
        width: 100%;
        display: inline-block;
      }
      &:hover {
        background-color: #cb5d35;
      }
      & img {
        padding: 0;
        max-width: 1.325rem;
      }
    }
    & .nav_item_with_submenu {
      position: relative;

      & svg {
        vertical-align: middle;
        margin-left: 0.2rem;
      }
    }
  }
}

.submenu {
  position: absolute;
  left: 0;
  & > li {
    margin-top: 0.5rem;
    & > a {
      width: 100%;
    }
  }
}

.menu_inverted {
  & ul {
    & .nav_item {
      background-color: var(--beige);
      color: var(--brown);

      & > a,
      > div {
        color: var(--brown);
      }
      &:hover {
        background-color: rgb(239, 220, 170);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .toggle {
    display: block;
  }
  .nav_container_desktop {
    display: none;
  }

  .nav_container_mobile {
    display: flex;
  }
}
