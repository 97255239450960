.page {
  background-color: var(--gray);
}
.container {
  padding: 0 6rem;
}
.header {
  width: 100%;
  z-index: 1;
  & img,
  picture {
    width: 100%;
    object-fit: contain;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5.125rem;
  align-items: center;

  & > img,
  > picture {
    width: 100%;
    background-size: contain;
  }
}

.grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 6.25rem;
  align-items: center;
  margin: 0 auto;
  margin-top: 9.125rem !important;
  overflow: hidden;
}
.heading {
  font-family: "Cool Daddy", "sans-serif";
  font-style: normal;
  font-size: 6.5rem;
  font-weight: 400;
  line-height: 85%; //115px;
  margin-bottom: 1rem;
  color: var(--brown);
  text-transform: uppercase;
  text-align: center;
}
.subheading {
  color: var(--dark);
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.details {
  color: var(--brown);
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 125%;
  text-align: center;
  margin-top: 1rem;
  max-width: 50vw;
}
.row_text {
}
.center_text {
  text-align: center;
}

/*G's CSS */
.container {
  margin-top: 2.5rem;
}
.bordered {
  // background-image: url("./assets/Graphics/border.svg");
  background-image: url("./assets/border.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 5.375rem;
  width: 366px;
}

.bottomArticle {
  margin-top: 2.5rem;
  & .details {
    margin-bottom: 1.5rem;
  }
  & img {
    width: 100%;
    object-fit: contain;
  }
}

.bottom_image {
  border: var(--width-400) solid var(--brown);
  border-radius: 1.5rem;
  width: 80%;
}

.bottom_image_container {
  position: relative;
  & > h2 {
    position: absolute;
    text-align: center;
    font-family: "Cool Daddy";
    color: var(--brown);
    font-weight: 400;
    width: 100%;
    font-size: 6rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 1024px) {
  .topArticle br {
    display: none;
  }
  .grid {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 2rem;
    align-items: center;
    margin: 0 auto !important;
    max-width: 100%;
  }

  .container {
    padding: 0 1rem;
  }

  .details {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  h1.heading {
    font-size: 3rem;
  }
  h2.heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1.5rem;
    margin-bottom: 0rem;
  }

  .details {
    font-size: 1.25rem;
  }

  .topArticle {
    margin-bottom: 2.5rem;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    flex-direction: column-reverse;
    & > img,
    > picture {
      width: 100%;
      background-size: contain;
    }
    &:nth-child(even) :last-child {
      order: -1;
    }
    .bordered {
      background-size: contain;
      width: 250px;
      margin-bottom: 0;
    }
  }

  .bottom_image {
    width: 100%;
  }

  .bottom_image_container {
    position: relative;
    & > h2 {
      position: absolute;
      text-align: center;
      font-family: "Cool Daddy";
      color: var(--brown);
      font-weight: 400;
      width: 100%;
      font-size: 4rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
