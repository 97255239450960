.root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 998;
  margin: 0 auto;

  & ::-webkit-scrollbar {
    display: none;
  }
  & ::-moz-scrollbar {
    display: none;
  }
}

.modal {
  background-color: var(--brown);
  width: 100%;
  margin-inline: 6rem;
  border-block: 1rem solid var(--beige);
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  z-index: 999;
  & .modal_header {
    padding-top: 1rem;
    position: sticky;
    background-color: var(--brown);
    top: 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .toggle {
      display: flex;
      align-items: center;
      font-family: "Cool Daddy";
      color: var(--beige);
      font-size: 2.5rem;
      &:hover {
        cursor: pointer;
      }
    }
    & .title {
      font-family: "Cool Daddy";
      color: var(--beige);
      font-size: 3.5rem;
    }
  }
  & .modal_body {
    color: var(--beige);
    font-family: "Fredoka";
    margin-block: 2rem;
    max-height: 84%;
    & p {
      font-size: 1.375rem;
      margin-bottom: 1rem;
    }
    & h2 {
      font-size: 1.625rem;
    }
    & img {
      position: sticky;
      top: 16.8%;
    }
  }
}

.modal_footer {
  display: flex;
  align-items: start;
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 16px;
  height: 1rem;
  background: var(--brown);

  & > ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    & .nav_item {
      white-space: nowrap;
      padding-inline: 0.5rem;
      margin-right: 0.5rem;
      font-family: "Cool Daddy";
      background-color: var(--beige);
      font-size: 1.375rem;
      border-radius: var(--width-400);
      & > a,
      > div {
        cursor: pointer;
        text-decoration: none;
        color: var(--brown);
        font-size: 1.5rem;
      }
      & img {
        max-width: 20px;
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

@media screen and (max-width: 1024px) {
  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;

    & .modal_body {
      margin-block: 1.5rem;
    }

    & .modal_footer {
      bottom: 4px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    margin-inline: 1rem;
    border-block: 0.25rem solid var(--beige);
    overflow-y: scroll;
    & ::-webkit-scrollbar {
      display: none;
    }
    & .modal_header {
      & .toggle {
        font-size: 2rem !important;
        & > span:first-child {
          display: none;
        }
      }
      & .title {
        font-size: 2rem !important;
      }
    }
    & .modal_body {
      font-size: 1rem;
      margin-block: 2rem;
      & img {
        display: none;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}
