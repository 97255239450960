.header {
  font-size: 0.275rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.375rem;
  z-index: 99;
}
.logo {
  width: 137px;
  cursor: pointer;
}
@media (min-width: 1000px) {
  .logo {
    width: 205px;
    cursor: pointer;
  }
  .header {
    font-size: 1rem;
    padding: 0 6rem;
  }
}
@media (min-width: 1600px) {
  .header {
    font-size: 2.5rem;
  }
}
@media (min-width: 3800px) {
  .header {
    font-size: 3.75rem;
  }
}
