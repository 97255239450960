.page {
  position: absolute;
  background-color: bisque;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16rem;
  line-height: 1.5;
  z-index: 1;
  //animation: page_transition 500ms ease-in-out 10ms 1;
}
.page a {
  text-decoration: none;
  font-size: 3rem;
}

@keyframes page_transition {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}