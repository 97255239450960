.page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;

  background-position: center;
  background-size: cover;
}

.container_buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  & button {
    max-width: 120px;
  }
}

.heading {
  font-family: "Cool Daddy", "sans-serif";
  font-style: normal;
  font-size: 6.5rem;
  font-weight: 400;
  line-height: 85%; //115px;
  margin-bottom: 1rem;
  color: var(--brown);
  text-transform: uppercase;
  text-align: start;
}
.heading_2 {
  font-family: "Cool Daddy", "sans-serif";
  font-style: normal;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 85%; //115px;
  margin-bottom: 1rem;
  color: var(--brown);
  text-transform: uppercase;
  text-align: start;
}
.subheading {
  color: var(--dark);
  font-size: 1.25rem;
  font-weight: 600;
}

.main_content {
  max-width: 1024px;
  width: 100%;
  margin-bottom: 5rem;
  margin-top: 2rem;
  & .nft_grid {
    margin-top: 2rem;
    max-width: 1024px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.75rem;
    row-gap: 2rem;
  }

  & button,
  a,
  input[type="submit"] {
    text-align: center;
    padding: 0.25rem 0.5rem;
    font-family: "Cool Daddy";
    background-color: var(--brown);
    font-size: 1.375rem;
    border-radius: var(--width-400);
    width: fit-content;
    margin-block: 0.3rem;

    cursor: pointer;
    text-decoration: none;
    color: var(--beige);
    display: inline-block;
    width: 100%;
    font-size: 1.5rem;
  }
}

.load_more_container {
  display: flex;
  align-items: center;
  justify-content: center;
  & button {
    margin-top: 2rem;
    padding-inline: 1rem;
    text-align: center;
    width: auto;
  }
}

.footer {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
  position: absolute;
  bottom: 0;
}

.mobile_disclaimer {
  text-align: center;
  color: var(--brown);
  padding-inline: 1rem;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  & h1 {
    font-family: "Cool Daddy";
    font-weight: 400;
  }
}

@media all and (max-width: 1024px) {
  .nft_grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
