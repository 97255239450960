.container {
  padding-inline: 6rem;
  width: 100%;
  margin: 0 auto;
}

.heading {
  font-size: 6.5rem;
  text-align: center;
  font-family: "Cool Daddy";
  color: var(--brown);
  font-weight: 400;
  margin: 3.5rem auto;
}

.team_avatar {
  display: flex;
  justify-content: center;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-template-rows: auto;
  grid-gap: 1em;
  width: 100%;
}

.banner_desktop {
  display: block;
}

.banner_mobile {
  display: none;
}

@media all and (max-width: 768px) {
  .grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
  }
  .container {
    padding-inline: 1rem;
  }

  .heading {
    font-size: 3rem;
    margin: 2rem auto;
  }

  .banner_desktop {
    display: none;
  }

  .banner_mobile {
    display: block;
  }
}
