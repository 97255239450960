.center_page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  & .error_heading,
  .error_info {
    font-weight: 400;
  }
}
.error_heading {
  font-family: "Cool Daddy", sans-serif;
  color: var(--brown);
  text-align: center;
  font-size: 3rem;
}
.error_info {
  margin-top: 2rem;
  text-align: center;
  font-family: "Cool Daddy", sans-serif;
  color: var(--brown);
  font-size: 4rem;
}
