.card {
  width: 100%;
  //border: 1px solid black;
}
.positioning_container {
  position: relative;
}
.avatar {
  margin-bottom: 0.15em;
  border: var(--width-200) solid var(--brown);
  border-radius: var(--border-radius);
  & > img {
    width: 100%;
    object-fit: cover;
    display: block;
  }
}
.name {
  font-family: "Cool Daddy", sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 80%;
  color: var(--brown);
  text-transform: uppercase;
  margin: 1rem auto;
}
.role {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 125%;
  color: var(--black);
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.description {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 125%;
  color: var(--brown);
  margin-bottom: 0.5em;
}
.socials {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-100%);
  width: fit-content;
  max-width: 30px;

  & > img {
    margin-right: 2em;
  }
}

@media screen and (max-width: 768px) {
  .name {
    font-size: 1.5rem;
    margin: 0.5rem auto;
  }
  .avatar {
    border: var(--width-200) solid var(--brown);
  }

  .role {
    font-size: 0.813rem;
    margin: 0.5rem auto;
  }
  .description {
    font-size: 0.875rem;
  }

  .socials {
    & > img {
      margin-right: 0;
      max-width: 20px;
    }
  }
}
