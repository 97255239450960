.footer {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 1em;
}

.egld {
  border-radius: 8px;
  border: none;
  width: 136px;
  height: 40px;
  margin-bottom: 0.25rem;
}

@media screen and (max-width: 768px) {
  .footer {
    margin-top: 1.313rem;
  }
}
