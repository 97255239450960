// VARIABLES
$grid-gap-lg: 0.5em;
$grid-gap-sm: 0.25em;

a {
  width: 100%;
}
.home_page {
  padding: $grid-gap-lg;
  position: absolute;
  // width: 100vw;
  inset: 0;
  background-color: var(--darkbrown);
  //overflow: hidden;
}
.home_grid {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  grid-template-rows: repeat(40, 1fr);
  grid-gap: $grid-gap-lg;

  width: 100%;
  height: 100%;
}

.home_grid_mobile {
  display: none;
  width: 100%;
  height: 100%;
}

.home_grid__item {
  font-weight: bolder;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.elem_1 {
  grid-column: 1/21;
  grid-row: 1/11;
}
.elem_2 {
  grid-column: 21/26;
  grid-row: 1/9;
  pointer-events: none;
}
.elem_3 {
  grid-column: 26/32;
  grid-row: 1/23;
  pointer-events: none;
}
.elem_4 {
  grid-column: 32/41;
  grid-row: 1/41;
}
.elem_5 {
  grid-column: 1/7;
  grid-row: 11/41;
}
.elem_6 {
  grid-column: 7/21;
  grid-row: 11/20;
}
.elem_7 {
  grid-column: 7/21;
  grid-row: 20/28;
}
.elem_8 {
  grid-column: 21/26;
  grid-row: 9/28;
}
.elem_9 {
  grid-column: 7/14;
  grid-row: 28/41;
}
.elem_10 {
  grid-column: 14/26;
  grid-row: 28/41;
}
.elem_11 {
  grid-column: 26/32;
  grid-row: 23/32;
}
.elem_12 {
  grid-column: 26/32;
  grid-row: 32/41;
}
//for mobile
@media only screen and (max-width: 1024px) {
  .widget_side {
    display: none !important;
  }
  .home_page {
    padding: $grid-gap-sm;
    position: relative;
  }
  .home_grid {
    display: none;
  }
  .home_grid_mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-gap: $grid-gap-sm;
  }

  .elem_1 {
    grid-column: 1/4;
    pointer-events: all;
    grid-row: 1;
  }
  .elem_7 {
    grid-column: 1/3;
    pointer-events: all;
    grid-row: 2;
  }
  .elem_12 {
    pointer-events: all;
    grid-column: 3/4;
    grid-row: 2;
  }

  .elem_2 {
    grid-column: 3/4;
    pointer-events: all;
    grid-row: 6;
  }
  .elem_5 {
    grid-column: 1/4;
    pointer-events: all;
    grid-row: 3;
  }
  .elem_4 {
    grid-column: 1/4;
    pointer-events: all;
    grid-row: 5;
  }
  .elem_6 {
    grid-column: 1/4;
    pointer-events: all;
    grid-row: 7;
  }
  .elem_8 {
    grid-column: 2/4;
    grid-row: 8;
    pointer-events: all;
  }
  .elem_9 {
    grid-column: 1/2;
    pointer-events: all;
    grid-row: 4;
  }
  .elem_10 {
    grid-column: 2/4;
    pointer-events: all;
    grid-row: 4;
  }
  .elem_11 {
    grid-column: 1/2;
    pointer-events: all;
    grid-row: 8;
  }
  .elem_3 {
    grid-column: 1/3;
    pointer-events: all;
    grid-row: 6;
  }
}

// @media screen and (min-aspect-ratio: 16/10) {
//   .home_grid {
//     display: none;
//   }
// }

//widget component
.widget_side {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  z-index: 999;
  background: var(--darkbrown);
  border-radius: var(--width-400);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .social_icon {
    background: var(--brown);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0.2rem;
    border-radius: var(--width-400);

    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
    &:hover {
      transform: scale(1.2);
    }
  }
}
