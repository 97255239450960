.container {
  font-family: "Cool Daddy";
  font-weight: 400;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.25rem;
  border-radius: var(--border-radius);
  width: 50%;
  position: relative;
  & div {
    width: 100%;
    text-align: center;
    position: absolute;
  }
  & .next,
  .back {
    width: 1.25rem;
    height: 1.25rem;
    background-color: transparent !important;
    padding: 0 !important;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
    animation: wiggle 2.5s infinite;
    z-index: 9999;
    & .arrow {
      width: 1.25rem;
      height: 1.25rem;
    }
    &:hover {
      animation: none;
      transform: scale(1.4);
    }
  }
}

// .back,
// .next {
//   transform: scale(1.2);
//   animation: wiggle 2.5s infinite;
// }

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(15deg);
  }
  95% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
