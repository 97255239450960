/*@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');*/
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@400;500;600&display=swap");
@font-face {
  font-family: "Cool Daddy";
  src: local("Cool Daddy"),
    url(../public/assets/Fonts/CoolDaddy-Regular.otf) format("opentype");
}

button,
input[type="submit"],
input[type="reset"],
input[type="text"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

:root {
  --gray: rgba(92, 110, 118, 1);
  /*--black: rgba(0, 0, 0, 1);*/
  --dark: #282623;
  --black: rgba(37, 37, 37, 1);
  --white: rgba(255, 255, 255, 1);
  --beige: rgba(254, 239, 196, 1);
  --brown: rgba(199, 82, 39, 1);
  --green-full: rgba(117, 186, 136, 1);
  --green-pale: rgba(228, 250, 228, 1);
  --darkbrown: #3f1919;
  --border-radius: 10px;
  --width-400: 4px;
  --width-200: 2px;
}
::-webkit-scrollbar {
  /*width: 0.75em;*/
}
::-webkit-scrollbar-track {
  /*background-color: rgba(70, 90, 98, 1);*/
}
::-webkit-scrollbar-thumb {
  /*background-color: var(--black);*/
  /*border: 3px solid rgba(70, 90, 98, 1);*/
  /*border-radius: 100vw;*/
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;

  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100vh;
  width: 100%;
  background-color: var(--beige);
}
body {
  font-family: "Fredoka", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}
