canvas {
  display: none;
}

.root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  margin: 0 auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  & ::-webkit-scrollbar {
    display: none;
  }
  & ::-moz-scrollbar {
    display: none;
  }
}

.modal {
  position: relative;
  width: 100%;
  margin-inline: 6rem;
  overflow-y: scroll;
  z-index: 999;

  border-radius: var(--border-radius);
  & .modal_header {
    padding-top: 1rem;
    position: sticky;

    top: 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-right: 4rem;
    & .toggle {
      display: flex;
      align-items: center;
      font-family: "Cool Daddy";

      transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
      font-size: 1.5rem;
      &:hover {
        transform: scale(1.2);
        cursor: pointer;
      }
    }
    & .title {
      font-family: "Cool Daddy";

      font-size: 3.5rem;
    }
  }
  & .modal_body {
    font-family: "Fredoka";
    max-height: 84%;
    & .image_container {
      display: flex;
      align-items: end;
      justify-content: center;
      position: relative;
    }
    & .data_container {
      margin-right: 4rem;
    }
    & p {
      font-size: 1.375rem;
      margin-bottom: 1rem;
    }
    & h2 {
      font-size: 1.625rem;
    }
    & img {
      position: sticky;
      top: 16.8%;
    }
    & .general_data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-block: 3rem;
      & .name {
        font-family: "Cool Daddy";
        font-size: 2rem;
      }
      & .rank > span {
        padding-inline: 0.5rem;
        padding-block: 0.25rem;
        border-radius: var(--width-400);
        font-weight: 600;
        font-size: 1.25rem;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    & .traits {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      & .trait {
        padding-inline: 0.5rem;
        padding-block: 0.5rem;
        border-radius: var(--width-400);
        background-color: rgba(0, 0, 0, 0.08);
        & h3 {
          font-size: 1.5rem;
        }
        & p {
          font-size: 1.25rem;
          margin-bottom: 0;
        }
      }
    }
    & .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5rem;

      margin-bottom: 7.5rem;
      & button {
        font-family: "Cool Daddy";
        font-weight: 400;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 0.25rem 1rem;
        border-radius: var(--width-400);
      }
    }
  }
}

.modal_footer {
  display: flex;
  align-items: start;
  width: 100%;
  left: 0;

  position: absolute;
  bottom: 16px;
  height: 1rem;

  & > ul {
    list-style: none;
    display: flex;
    width: 100%;
    flex-direction: row;
    & .nav_item {
      white-space: nowrap;
      padding-inline: 0.5rem;
      margin-right: 0.5rem;
      font-family: "Cool Daddy";
      font-size: 1.375rem;
      border-radius: var(--width-400);
      & > a,
      > div {
        cursor: pointer;
        text-decoration: none;
        color: var(--brown);
        font-size: 1.5rem;
      }
      & img {
        max-width: 20px;
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.logos {
  position: absolute;
  bottom: 1.75rem;
  right: 4rem;
  & > a {
    margin-inline: 0.5rem;
  }
}
