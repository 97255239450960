.page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-image: url("./assets/web.webp");

  background-position: center;
  background-size: cover;
}
.main_content {
  padding-inline: 6rem;
  .container_buttons {
    max-width: 500px;
  }
  & .prompt {
    text-align: center;
  }

  & .response {
    font-family: "Cool Daddy";
    color: var(--brown);
    margin-block: 1rem;
    font-size: 2rem;
    text-align: center;
    & h2 {
      font-weight: 400;
      font-size: 4.5rem;
    }
    & p {
      font-size: 1.75rem;
      font-weight: 400;
    }
    & a {
      width: auto;
    }
  }

  & input[type="text"] {
    width: 100%;
    border-bottom: 2px solid var(--brown);
    margin-block: 1rem;
    font-size: 2rem;
    text-align: center;
  }
  & button,
  a,
  input[type="submit"] {
    text-align: center;
    padding: 0.25rem 0.5rem;
    font-family: "Cool Daddy";
    background-color: var(--brown);
    font-size: 1.375rem;
    border-radius: var(--width-400);
    width: fit-content;
    margin-block: 0.3rem;

    cursor: pointer;
    text-decoration: none;
    color: var(--beige);
    display: inline-block;
    width: 100%;
    font-size: 1.5rem;
  }
}

.footer {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.mobile_disclaimer {
  text-align: center;
  color: var(--brown);
  padding-inline: 1rem;
  & h1 {
    font-family: "Cool Daddy";
    font-weight: 400;
  }
}
@media screen and (max-width: 1024px) {
  .page {
    background-image: url("./assets/phone.webp");
  }
}
