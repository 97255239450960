.page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #fdf1cb;
}
.video_container {
  padding-inline: 6rem;
  & video {
    max-width: 80%;
    margin: auto;
    display: flex;
  }
}

.footer {
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.legal {
  text-align: center;
  width: 600px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  width: 200px;
}

a {
  color: black;
}

.flex_button {
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .legal {
    text-align: center;
    width: 300px;
    font-size: small;
  }
}

@media screen and (max-width: 1024px) {
  .video_container {
    padding-inline: 1rem;

    & video {
      width: 100%;
      max-width: 100%;
    }
  }
}
