.card {
  width: 100%;
  transition-duration: 0.5s;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;

  border: var(--width-400) solid var(--brown);
  border-radius: var(--border-radius);
}

.links {
  display: flex;
  justify-content: start;
  gap: 0.5rem;
  & a {
    width: 22px;
    height: 22px;
    background-color: transparent;
    padding: 0;

    & img {
      padding: none;
    }
  }
}
.image {
  margin-bottom: 0.15em;
  & > img {
    width: 100%;
    object-fit: cover;
    display: block;

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
}
.title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark);
}
.description {
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 125%;
  color: var(--brown);
  margin-bottom: 0.5em;
}

.body {
  padding: 0.5rem 1rem;
}

.eligible {
  font-size: 1rem;

  color: var(--gray);
  width: auto;
  padding: 0;
  font-size: 1rem;
  background-color: var(--green-pale);
  color: var(--green-full);

  font-size: 0.725rem;
  font-weight: 500;
  line-height: 125%;
  font-family: "Fredoka", sans-serif;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.125rem;
  padding: 0.125rem 0.25rem;
  border-radius: var(--width-400);
}

.buyNow {
  & a {
    font-size: 1rem;
    background-color: transparent;

    color: var(--gray);
    width: auto;
    padding: 0;
    font-size: 1rem;

    font-size: 0.725rem;
    font-weight: 500;
    line-height: 125%;
    font-family: "Fredoka", sans-serif;
  }
}

@media screen and (max-width: 768px) {
  .name {
    font-size: 1.5rem;
    margin: 0.5rem auto;
  }
  .card {
    border: var(--width-200) solid var(--brown);
  }
  .description {
    font-size: 0.875rem;
  }
}
