.container {
  padding: 0 6rem;
  margin-top: 3rem;
}
.secondary_heading {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 125%;
  color: var(--black);
  margin-bottom: 3.75em;
}
.mission_text {
  & > p {
    margin-bottom: 2rem;
  }
}
.mission_img {
  display: flex;
  align-items: flex-start;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5.125rem;
  align-items: center;

  & > img {
    width: 100%;
    background-size: contain;
  }
}

.grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  grid-column-gap: 1.25em;
  grid-row-gap: 3.25em;
}
.heading {
  font-family: "Cool Daddy", "sans-serif";
  font-style: normal;
  font-size: 6.5rem;
  font-weight: 400;
  line-height: 85%; //115px;
  margin-bottom: 1rem;
  color: var(--brown);
  text-transform: uppercase;
  text-align: start;
}
.subheading {
  color: var(--dark);
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.details,
.grid_element__description,
.mission_text {
  color: var(--brown);
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 125%;
  text-align: left;
  margin-top: 1rem;
  max-width: 50vw;
}
.grid_top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1.25em;
  grid-row-gap: 3.25em;
}
.grid_element {
  padding: 1em;
  border: var(--width-200) solid var(--brown);
  border-radius: 1.5rem;
}
.grid_element__heading {
  font-size: 3rem;
  font-weight: 600;
  line-height: 85%;
  color: var(--black);
}

.section {
  margin-top: 6.25rem;
}

@media screen and (max-width: 1024px) {
  .section {
    margin-top: 2rem;
  }

  .grid,
  .grid_top {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    align-items: center;
    margin: 0 auto !important;
    max-width: 100%;
  }
  .grid {
    grid-row-gap: 2rem;
  }

  .container {
    padding: 0 1rem;
  }

  .grid_element {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .details,
  .mission_text,
  .grid_element__description {
    max-width: 100%;
    font-size: 1.25rem;
  }

  .heading {
    font-size: 3rem;
  }

  .subheading,
  .grid_element__heading {
    font-size: 1.5rem;
  }

  .grid_element__heading,
  .grid_element__description {
    font-size: 1rem;
  }

  .grid_element__description {
    margin-block: 0.5rem;
  }
}
