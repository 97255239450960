.plan_container {
  position: relative;
  padding-inline: 6rem;
  width: 100%;
  margin: 0 auto;
  > span {
    position: absolute;
  }

  & .desktop_img {
    display: block;
  }
  & .mobile_img {
    display: none;
  }

  & .map_label {
    white-space: nowrap;
    padding: 1.65rem;
    margin-inline: 0.25rem;
    font-family: "Cool Daddy";
    background-color: var(--beige);
    color: var(--brown);
    font-size: 3vw;
    border-radius: var(--border-radius);
    position: absolute;
    z-index: 9;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in;

    &:hover {
      transform: scale(1.05);
      background-color: rgb(239, 220, 170);
    }
  }
}

.page_heading {
  padding-inline: 6rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
  & h1 {
    font-family: "Cool Daddy", "sans-serif";
    font-style: normal;
    font-size: 6.5rem;
    font-weight: 400;
    line-height: 85%; //115px;
    margin-bottom: 1rem;
    color: var(--beige);
    text-transform: uppercase;
    text-align: start;
  }
  & h2 {
    font-family: "Fredoka";
    font-size: 3rem;
    color: var(--dark);
    margin-bottom: 2rem;
  }
}

//positioning

div#atelier {
  top: 26%;
  left: 18%;
}
div#mall {
  top: 12%;
  left: 41%;
}
div#uni {
  top: 27%;
  left: 64%;
}
div#town {
  top: 51%;
  left: 41%;
}
div#factory {
  top: 70%;
  left: 18%;
}
div#social_hub {
  top: 81%;
  left: 46%;
}
div#central_station {
  top: 67%;
  left: 77%;
}

@media screen and (max-width: 1024px) {
  .plan_container {
    padding-inline: 1rem;

    & .mobile_img {
      display: block;
    }
    & .desktop_img {
      display: none;
    }

    & .map_label {
      padding: 0.5rem;
      margin-inline: 0.25rem;
      font-size: 4vw;
    }
  }

  .page_heading {
    padding-inline: 1rem;
    & h1 {
      font-family: "Cool Daddy";
      font-size: 3rem;
      color: var(--beige);
    }
    & h2 {
      font-family: "Fredoka";
      font-size: 1.5rem;
      color: var(--dark);
      margin-bottom: 1rem;
    }
  }

  //positioning

  div#atelier {
    top: 73%;
    left: 27%;
  }
  div#mall {
    top: 50%;
    left: 10%;
  }
  div#uni {
    top: 22%;
    left: 27%;
  }
  div#town {
    top: 45%;
    left: 45%;
  }
  div#factory {
    top: 73%;
    left: 65%;
  }
  div#social_hub {
    top: 26%;
    left: 67%;
  }
  div#central_station {
    top: 9%;
    left: 67%;
  }
}
